import type { NextPage } from 'next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from 'react';
import axios, { HeadersDefaults } from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export interface CommonHeaderProperties extends HeadersDefaults {
  tenantCode: string;
}

export function navigateToNotFound(): void {
  if (window.location.pathname.indexOf('/not-found') !== 0)
    window.location.href =
      window.location.protocol + '//' + window.location.host + '/not-found';
}

const Home: NextPage = (props: any) => {

    useEffect(() => {
        console.log('version: 0.5')
    }, []);

  return (
      <>
          <div style={{ overflow: 'hidden' }}>
              <img src="https://kitouchme-assets.s3.amazonaws.com/s3fs-public/2022-04/Banner%20image1.jpg"
                  style={{ width:'100vw', height: '99vh' }}
                   alt="Your LAST Business Card" title="Your LAST Business Card" typeof="foaf:Image" />
              </div>
    </>
  )
}

export default Home
